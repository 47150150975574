import React, { useState } from 'react';

//bs
import {
	Navbar,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	Container,
	Row,
	Collapse,
	NavbarToggler,
} from 'reactstrap';

//router
import { NavLink as RDNavLink } from 'react-router-dom';

//images
import LogoIMG from 'css/images/logo.svg';

const Navigation = () => {
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	return (
		<div className="main-header">
			<Container>
				<Row>
					<Navbar color="main-bar" light expand="lg">
						<NavbarBrand to="/" tag={RDNavLink} className="logo-rg">
							<div className="logo">
								<img src={LogoIMG} alt="Rocío González" />
							</div>
						</NavbarBrand>
						<NavbarToggler onClick={toggle} />
						<Collapse isOpen={isOpen} navbar>
							<Nav className="mr-auto" navbar>
								<NavItem>
									<NavLink
										to="/sobre-mi"
										tag={RDNavLink}
										activeclassname="active"
										onClick={toggle}
									>
										Sobre Mi
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to="/servicios"
										tag={RDNavLink}
										activeclassname="active"
										onClick={toggle}
									>
										Servicios
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to="/somos-momentos"
										tag={RDNavLink}
										activeclassname="active"
										onClick={toggle}
									>
										Somos Momentos
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to="/contacto"
										tag={RDNavLink}
										activeclassname="active"
										onClick={toggle}
									>
										Contacto
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to="/blog"
										tag={RDNavLink}
										activeclassname="active"
										onClick={toggle}
									>
										Blog
									</NavLink>
								</NavItem>
							</Nav>
						</Collapse>
					</Navbar>
				</Row>
			</Container>
		</div>
	);
};

export default Navigation;
