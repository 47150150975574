import React, { useState, useRef } from 'react';

//bs
import {
	Container,
	Row,
	Col,
	Button,
	Input,
	Label,
	FormGroup,
	FormFeedback,
} from 'reactstrap';

//form
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { swalError } from 'utils/helpers';

//comps
import * as mainApi from 'api/mainApi';
import LoadingLayer from 'components/loaders/LoadingLayer';

const Newsletter = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isVisible, setIsVisible] = useState(true);
	const formikRef = useRef();

	const defForm = {
		nombre: '',
		mail: '',
		privacy: false,
	};

	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required('El nombre es obligatorio'),
		mail: Yup.string()
			.email('Ingresa un formato de correo válido')
			.required('El email es obligatorio'),
		privacy: Yup.boolean().oneOf(
			[true],
			'Debes aceptar la política de privacidad'
		),
	});

	const handleSubmitForm = async (formValues) => {
		setIsLoading(true);
		const data = await mainApi.sendNewsletter(formValues);
		if (data && data.succeded) {
			setIsVisible(!isVisible);
			formikRef.current.resetForm(defForm);
		} else {
			swalError(data.message);
		}
		setIsLoading(false);
	};

	return (
		<section className="newsletter-module">
			<LoadingLayer isVisible={isLoading} />
			<Container>
				<Row className="align-items-center">
					<Col md="1"></Col>
					<Col md="6">
						<h1>Event Planner</h1>
						<p>
							¿Quieres estar al día de todas las novedades y tendencias del
							apasionado mundo de los eventos y las bodas?
						</p>
						<p>
							Inscríbete a nuestra newsletter para estar al tanto de las últimas
							novedades tips, descuentos, y mucho más
							<span>¿A qué esperas para saberlo todo?</span>
						</p>
					</Col>

					<Col md="4">
						<div className="box-form">
							{isVisible ? (
								<Formik
									innerRef={formikRef}
									enableReinitialize
									initialValues={defForm}
									validateOnBlur={false}
									validateOnChange={false}
									validationSchema={validationSchema}
									onSubmit={handleSubmitForm}
								>
									{({ isSubmitting, errors }) => {
										return (
											<Form autoComplete="off">
												<FormGroup row>
													<Label for="formName" sm={3}>
														Nombre
													</Label>
													<Col sm={9}>
														<Input
															id="formName"
															name="nombre"
															tag={Field}
															placeholder="Tu nombre"
															type="text"
														/>
														{errors.nombre && (
															<FormFeedback>{errors.nombre}</FormFeedback>
														)}
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label for="formMail" sm={3}>
														Email
													</Label>
													<Col sm={9}>
														<Input
															id="formMail"
															name="mail"
															placeholder="Tu email"
															tag={Field}
															type="email"
														/>
														{errors.mail && (
															<FormFeedback>{errors.mail}</FormFeedback>
														)}
													</Col>
												</FormGroup>
												<FormGroup className="mb-4">
													<Input
														tag={Field}
														name="privacy"
														id="privacy"
														type="checkbox"
														autoComplete="off"
														className="me-2 roci-check"
													/>
													<Label for="privacy">
														He leído y acepto la política de privacidad{' '}
													</Label>
													{errors.privacy && (
														<FormFeedback>{errors.privacy}</FormFeedback>
													)}
												</FormGroup>
												<div className="text-center">
													<Button
														type="submit"
														color="default"
														disabled={isSubmitting}
													>
														Suscribirme
													</Button>
												</div>
											</Form>
										);
									}}
								</Formik>
							) : (
								<div className="thx">
									Gracias por suscribirte a nuestra newsletter
								</div>
							)}
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Newsletter;
