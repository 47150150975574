/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { NavLink } from 'react-router-dom';

//bs
import { Container, Row, Col } from 'reactstrap';

//images
import SignatureImg from 'css/images/r-signature.svg';
import LogoInsta from 'css/images/instagram.svg';
import LogoMail from 'css/images/mail.svg';

const Footer = () => {
	return (
		<Container>
			<Row>
				<Col className="mx-auto" md="7">
					<div className="footer-layer">
						<div className="footer-sign">
							<img src={SignatureImg} alt="Rocío González" />
						</div>
						<div className="redes">
							<a href="https://instagram.com/rociogonzalezevents" target="_blank" rel="noreferrer">
								<img src={LogoInsta} alt="Instagram" />
							</a>

							<NavLink to="/contacto">
								<img src={LogoMail} alt="Mail" />
							</NavLink>
						</div>
						<div className="info">
							<p>© 2024 Rocío González - Todos los derechos reservados.</p>
							<p>Diseño web ~ Mateagenciacreativa</p>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Footer;
