import Reviews from 'components/common/Reviews';
import React from 'react';

//bs
import { Container, Row, Col } from 'reactstrap';

import { Slide } from 'react-awesome-reveal';

const AboutPage = ({ appData }) => {
	return (
		<div className="about-page">
			<Container>
				<Row>
					<Col md="4" className="mx-auto">
						<div className="intro-section">
							<h1>SOBRE MI</h1>
							<p>{appData.about.mainTx}</p>
						</div>
					</Col>
				</Row>
				<Row className="mb-5 pb-5">
					<Col md="10" className="mx-auto">
						<Slide direction="up" triggerOnce>
							<div className="big-photo">
								<img
									src="/images/about/head.jpg"
									alt="Sobre mi"
									className="img-fluid"
								/>
							</div>
						</Slide>
					</Col>
				</Row>
			</Container>
			<Container fluid className="bg-rosesection px-0">
				<Row className="g-0 align-items-center">
					<Col md="5">
						<Slide direction="left" triggerOnce>
							<img
								src="/images/about/body.jpg"
								alt="Rocío González Events"
								className="img-fluid"
							/>
						</Slide>
					</Col><Col md="7">
						<Slide direction="right" triggerOnce>
							<div className="info">
								<h1>¡Hola, Soy Rocío!</h1>
								<p>{appData.about.hello}</p>
							</div>
						</Slide>
					</Col>
					
				</Row>
			</Container>
			<Container>
				<Row className="mt-5 py-5">
					<Col lg="12">
						<h1 className="tx-green text-center">
							¡Momentos que hacen historia!
						</h1>
					</Col>
				</Row>
				<Reviews />
			</Container>
			<div className="spacer-bottom"></div>
		</div>
	);
};

export default AboutPage;
