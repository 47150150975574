/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';

//bs
import { Container, Row, Col, Button } from 'reactstrap';

//icons
import { FaInstagram, FaRegEnvelope, FaRegUser } from 'react-icons/fa6';
import { IoPhonePortraitOutline, IoLocationOutline } from 'react-icons/io5';
import ContactForm from 'components/common/ContactForm';

const ContactPage = ({ appData }) => {
	return (
		<div className="contact-page">
			<Container>
				<Row>
					<Col md="6" className="mx-auto">
						<div className="intro-section">
							<h1>¡CUÉNTANOSLO TODO!</h1>
							<p>{appData.contacto.mainTx}</p>
							<h1>¿Hablamos?</h1>
						</div>
					</Col>
				</Row>
			</Container>
			<div className="contact-layer bg-green">
				<Container>
					<Row className="align-items-center">
						<Col md="6">
							<div className="big-photo">
								<img
									src="/images/contact/contact-form.jpg"
									alt="Rocio Gonzalez"
									className="img-fluid"
								/>
							</div>
						</Col>
						<Col md="6">
							<ContactForm />
						</Col>
					</Row>
				</Container>
			</div>
			<div className="contact-footer">
				<Container>
					<Row className="align-items-center">
						<Col md="6">
							<div className="info">
								<h1>Hablemos de tu gran evento:</h1>
								<div className="contact-info">
									<IoPhonePortraitOutline size={22} />
									<p> 666 666 666</p>
								</div>
								<div className="contact-info">
									<FaRegEnvelope size={19} />
									<p>hola@rociogonzalez.es</p>
								</div>
								<div className="contact-info">
									<FaInstagram size={21} />
									<p>
										<a
											href="https://instagram.com/rociogonzalezevents"
											target="_blank"
											rel="noreferrer"
										>
											@rociogonzalezevents
										</a>
									</p>
								</div>
								<div className="contact-info">
									<FaRegUser size={20} />
									<p>Reserva tu asesoria gratuita</p>
								</div>
								<div className="contact-info">
									<IoLocationOutline size={21} />
									<p>Madrid</p>
								</div>
							</div>
						</Col>
						<Col md="6">
							<div className="legal">
								<p>
									INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS
									<br />
									<u>Responsable: </u>
									Rocío González <br /> <u>Finalidad:</u> responder a la
									solicitud.
									<br />
									<u>Legitimación: </u>consentimiento del interesado. <br />
									Destinatarios: sus datos se encuentran alojados en mi hosting
									en OVH Cloud.
									<br /> <u>Duración:</u> mientras dure la relación contractual
									o 12 meses desde el último mensaje enviado. <br />{' '}
									<u>Derechos:</u> puede ejercitar en cualquier momento sus
									derechos de acceso, rectificación, supresión, oposición y
									demás derechos legalmente establecidos a través del email
									hola@rociogonzalez.es <br /> <u>Información adicional:</u>{' '}
									encontrará más información en la política de privacidad.
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="spacer-bottom"></div>
		</div>
	);
};

export default ContactPage;
