import React from 'react';

//main css
import './css/app.scss';

//routing
import { BrowserRouter as Router, Switch } from 'react-router-dom';

//main
import Layout from './components/Layout';

//paginas
import HomePage from './pages/home/HomePage';
import AboutPage from './pages/about/AboutPage';
import ContactPage from './pages/contact/ContactPage';
import ServicesPage from './pages/services/ServicesPage';
import MomentsPage from './pages/moments/MomentsPage';
import BlogPage from './pages/blog/BlogPage';

//errors
import NotFoundPage from './pages/generic/NotFoundPage';

//helpers
import ScrollToTop from 'components/ScrollToTop';

const App = () => {
	return (
		<Router>
			<ScrollToTop>
				<Switch>
					<Layout exact path="/" component={HomePage} />

					<Layout path="/sobre-mi" component={AboutPage} />

					<Layout path="/servicios" component={ServicesPage} />
					<Layout path="/somos-momentos" component={MomentsPage} />
					<Layout path="/contacto" component={ContactPage} />

					<Layout path="/blog/:slug" component={BlogPage} />
					<Layout path="/blog" component={BlogPage} />

					<Layout component={NotFoundPage} />
				</Switch>
			</ScrollToTop>
		</Router>
	);
};

export default App;
