import React from 'react';
import { Slide } from 'react-awesome-reveal';
import { NavLink } from 'react-router-dom';

//bs
import { Container, Row, Col } from 'reactstrap';

const ServicesPage = ({ appData }) => {
	return (
		<div className="services-page">
			<Container>
				<Row>
					<Col md="6" className="mx-auto">
						<div className="intro-section">
							<h1>SERVICIOS</h1>
							<p>{appData.servicios.mainTx}</p>
						</div>
					</Col>
				</Row>
			</Container>
			<div className="services-hero bg-green" id="el-gran-momento">
				<Container>
					<Row>
						<Col>
							<h1>El gran momento</h1>
						</Col>
					</Row>
				</Container>
			</div>
			<Container fluid className="px-0">
				<Row className="bg-rosesection align-items-center g-0">
					<Col md="6">
						<Slide direction="left" triggerOnce>
							<img
								src="/images/servicios/1.jpg"
								alt="Servicio Integral"
								className="img-fluid"
							/>
						</Slide>
					</Col>

					<Col md="6">
						<Slide direction="right" triggerOnce>
							<div className="info">
								<h1>Servicio Integral:</h1>
								<p>{appData.servicios.servicio1}</p>
								<NavLink to="/contacto" className="btn btn-info">
									Solicitar información
								</NavLink>
							</div>
						</Slide>
					</Col>
				</Row>
				<Row className="g-0 align-items-center">
					<Col md="6" className="order-2 order-lg-1">
						<Slide direction="left" triggerOnce>
							<div className="info">
								<h1>Día B:</h1>
								<p>{appData.servicios.servicio2}</p>
								<NavLink to="/contacto" className="btn btn-info">
									Solicitar información
								</NavLink>
							</div>
						</Slide>
					</Col>
					<Col md="6" className="order-1 order-lg-2">
						<Slide direction="right" triggerOnce>
							<img
								src="/images/servicios/2.jpg"
								alt="Día B"
								className="img-fluid"
							/>
						</Slide>
					</Col>
				</Row>
				<Row className="g-0 bg-rosesection align-items-center">
					<Col md="6">
						<Slide direction="left" triggerOnce>
							<img
								src="/images/servicios/3.jpg"
								alt="Tutorías personalizadas"
								className="img-fluid"
							/>
						</Slide>
					</Col>
					<Col md="6">
						<Slide direction="right" triggerOnce>
							<div className="info">
								<h1>Tutorías personalizadas:</h1>
								<p>{appData.servicios.servicio3}</p>
								<NavLink to="/contacto" className="btn btn-info">
									Solicitar información
								</NavLink>
							</div>
						</Slide>
					</Col>
				</Row>
			</Container>
			<div className="services-hero bg-green" id="cualquier-momento-es-bueno">
				<Container>
					<Row>
						<Col md="4" className="mx-auto">
							<h1>Cualquier momento es bueno para celebrar</h1>
						</Col>
					</Row>
				</Container>
			</div>
			<Container fluid className="px-0">
				<Row className="align-items-center g-0">
					<Col md="6">
						<Slide direction="left" triggerOnce>
							<div className="info">
								<h1>Otros Eventos:</h1>
								<p>{appData.servicios.servicio4}</p>
								<NavLink to="/contacto" className="btn btn-info">
									Solicitar información
								</NavLink>
							</div>
						</Slide>
					</Col>
					<Col md="6">
						<Slide direction="right" triggerOnce>
							<img
								src="/images/servicios/4.jpg"
								alt="Servicio Integral"
								className="img-fluid"
							/>
						</Slide>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default ServicesPage;
