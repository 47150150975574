import Newsletter from 'components/common/Newsletter';
import React from 'react';

//bs
import { Container, Row, Col, Button } from 'reactstrap';

import { Slide } from 'react-awesome-reveal';

const BlogPage = ({ appData }) => {
	return (
		<div className="blog-page">
			<Container>
				<Row>
					<Col md="6" className="mx-auto">
						<div className="intro-section">
							<h1>NUESTRO BLOG</h1>
							<p>{appData.blog.mainTx}</p>
						</div>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<div className="grid-blog">
							{appData.blog &&
								appData.blog.posts &&
								appData.blog.posts.map((item, index) => (
									<Slide key={index} direction="up" triggerOnce>
										<div key={index} className="blogpost">
											<div className="entry">
												<img
													src={item.img}
													alt={item.title}
													className="img-fluid"
												/>
												<div className="tit">{item.title}</div>
											</div>

											<Button color="warning">Leer más</Button>
										</div>
									</Slide>
								))}
						</div>
					</Col>
				</Row>
			</Container>
			<div className="spacer-bottom"></div>
			<Newsletter />
		</div>
	);
};

export default BlogPage;
