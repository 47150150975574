import React, { useState, useRef } from 'react';

//bs
import {
	Col,
	Button,
	Input,
	Label,
	FormGroup,
	FormFeedback,
} from 'reactstrap';

//form
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { swalError } from 'utils/helpers';

import * as mainApi from 'api/mainApi';
import LoadingLayer from 'components/loaders/LoadingLayer';

const ContactForm = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [formVisible, setFormVisible] = useState(true);
	const formikRef = useRef();

	const defForm = {
		nombre: '',
		mail: '',
		telef: '',
		fechaevento: '',
		numinvitados: '',
		lugar: '',
		presu: '',
		como: '',
		tipoEvento: [],
		privacy: false,
	};

	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required('El nombre es obligatorio'),
		mail: Yup.string()
			.email('Introduce un formato de correo válido')
			.required('El email es obligatorio'),
		telef: Yup.string().required('El teléfono es obligatorio'),
		privacy: Yup.boolean().oneOf(
			[true],
			'Debes aceptar la política de privacidad'
		),
	});

	const handleSubmitForm = async (formValues) => {
		setIsLoading(true);
		const data = await mainApi.sendContact(formValues);
		if (data && data.succeded) {
			setFormVisible(!formVisible);
			formikRef.current.resetForm(defForm);
		} else {
			swalError(data.message);
		}
		setIsLoading(false);
	};

	const handleEventTypeChange = (e, values, setFieldValue, eventType) => {
		if (e.target.checked) {
			setFieldValue('tipoEvento', [...values.tipoEvento, eventType]);
		} else {
			setFieldValue(
				'tipoEvento',
				values.tipoEvento.filter((value) => value !== eventType)
			);
		}
	};

	return (
		<div className="contact-form">
		<LoadingLayer isLoading={isLoading} />
			{formVisible ? (
				<Formik
					innerRef={formikRef}
					enableReinitialize
					initialValues={defForm}
					validateOnBlur={false}
					validateOnChange={false}
					validationSchema={validationSchema}
					onSubmit={handleSubmitForm}
				>
					{({ isSubmitting, errors, values, setFieldValue }) => {
						console.log(values);
						return (
							<Form autoComplete="off">
								<FormGroup row className="mb-4">
									<Col sm={6}>
										<Input
											id="formName"
											name="nombre"
											tag={Field}
											type="text"
											placeholder="Nombre *"
										/>
										{errors.nombre && (
											<FormFeedback>{errors.nombre}</FormFeedback>
										)}
									</Col>

									<Col sm={6}>
										<Input
											id="formTelf"
											name="telef"
											tag={Field}
											type="text"
											placeholder="Teléfono *"
										/>
										{errors.telef && (
											<FormFeedback>{errors.telef}</FormFeedback>
										)}
									</Col>
								</FormGroup>
								<FormGroup row className="mb-4">
									<Col sm={6}>
										<Input
											id="formMail"
											name="mail"
											tag={Field}
											type="email"
											placeholder="Email *"
										/>
										{errors.mail && <FormFeedback>{errors.mail}</FormFeedback>}
									</Col>

									<Col sm={6}>
										<Input
											id="formdate"
											name="fechaevento"
											tag={Field}
											type="text"
											placeholder="Fecha del evento"
										/>
									</Col>
								</FormGroup>

								<FormGroup row className="mb-4">
									<Col sm={6}>
										<Input
											id="numinvitados"
											name="numinvitados"
											tag={Field}
											type="text"
											placeholder="Número de invitados"
											className="mb-4"
										/>
										<Input
											id="lugar"
											name="lugar"
											tag={Field}
											type="text"
											placeholder="Lugar del evento"
											className="mb-4"
										/>
										<Input
											id="presu"
											name="presu"
											tag={Field}
											type="text"
											placeholder="Presupuesto estimado"
											className="mb-4"
										/>
										<Input
											id="como"
											name="como"
											type="textarea"
											rows="4"
											placeholder="Cómo os puedo ayudar"
											className="mb-4"
                                 onChange={(e) => setFieldValue('como', e.target.value)}
										/>
									</Col>

									<Col sm={6}>
										<Label>Tipo de evento</Label>

										<FormGroup check>
											<Input
												tag={Field}
												id="ev1"
												type="checkbox"
												autoComplete="off"
												className="me-2 roci-check"
												value="boda-religiosa"
												checked={values.tipoEvento.includes('boda-religiosa')}
												onChange={(e) =>
													handleEventTypeChange(
														e,
														values,
														setFieldValue,
														'boda-religiosa'
													)
												}
											/>
											<Label htmlFor="ev1">Boda religiosa</Label>
										</FormGroup>

										<FormGroup check>
											<Input
												tag={Field}
												id="ev2"
												type="checkbox"
												autoComplete="off"
												className="me-2 roci-check"
												value="boda-civil"
												checked={values.tipoEvento.includes('boda-civil')}
												onChange={(e) =>
													handleEventTypeChange(
														e,
														values,
														setFieldValue,
														'boda-civil'
													)
												}
											/>
											<Label htmlFor="ev2">Boda civil</Label>
										</FormGroup>
										<FormGroup check>
											<Input
												tag={Field}
												id="ev3"
												type="checkbox"
												autoComplete="off"
												className="me-2 roci-check"
												value="evento-corporativo"
												checked={values.tipoEvento.includes(
													'evento-corporativo'
												)}
												onChange={(e) =>
													handleEventTypeChange(
														e,
														values,
														setFieldValue,
														'evento-corporativo'
													)
												}
											/>
											<Label htmlFor="ev3">Evento corporativo</Label>
										</FormGroup>
										<FormGroup check>
											<Input
												tag={Field}
												id="ev4"
												type="checkbox"
												autoComplete="off"
												className="me-2 roci-check"
												value="fiestas-privadas"
												checked={values.tipoEvento.includes('fiestas-privadas')}
												onChange={(e) =>
													handleEventTypeChange(
														e,
														values,
														setFieldValue,
														'fiestas-privadas'
													)
												}
											/>
											<Label htmlFor="ev4">Fiestas privadas</Label>
										</FormGroup>
										<FormGroup check>
											<Input
												tag={Field}
												id="ev5"
												type="checkbox"
												autoComplete="off"
												className="me-2 roci-check"
												value="aniversarios"
												checked={values.tipoEvento.includes('aniversarios')}
												onChange={(e) =>
													handleEventTypeChange(
														e,
														values,
														setFieldValue,
														'aniversarios'
													)
												}
											/>
											<Label htmlFor="ev5">Aniversarios</Label>
										</FormGroup>
										<FormGroup check>
											<Input
												tag={Field}
												id="ev6"
												type="checkbox"
												autoComplete="off"
												className="me-2 roci-check"
												value="eventos-infantiles"
												checked={values.tipoEvento.includes(
													'eventos-infantiles'
												)}
												onChange={(e) =>
													handleEventTypeChange(
														e,
														values,
														setFieldValue,
														'eventos-infantiles'
													)
												}
											/>
											<Label htmlFor="ev6">Eventos infantiles</Label>
										</FormGroup>
										<FormGroup check>
											<Input
												tag={Field}
												id="ev7"
												type="checkbox"
												autoComplete="off"
												className="me-2 roci-check"
												value="fiestas-tematicas"
												checked={values.tipoEvento.includes(
													'fiestas-tematicas'
												)}
												onChange={(e) =>
													handleEventTypeChange(
														e,
														values,
														setFieldValue,
														'fiestas-tematicas'
													)
												}
											/>
											<Label htmlFor="ev7">Fiestas temáticas</Label>
										</FormGroup>
										<FormGroup check>
											<Input
												tag={Field}
												id="ev8"
												type="checkbox"
												autoComplete="off"
												className="me-2 roci-check"
												value="otros"
												checked={values.tipoEvento.includes('otros')}
												onChange={(e) =>
													handleEventTypeChange(
														e,
														values,
														setFieldValue,
														'otros'
													)
												}
											/>
											<Label htmlFor="ev8">Otros…</Label>
										</FormGroup>
									</Col>
								</FormGroup>

								<FormGroup className="ms-4 mb-4">
									<Input
										tag={Field}
										name="privacy"
										id="privacy"
										type="checkbox"
										autoComplete="off"
										className="me-2 roci-check"
									/>
									<Label for="privacy">
										He leído y acepto la política de privacidad
									</Label>
									{errors.privacy && (
										<FormFeedback>{errors.privacy}</FormFeedback>
									)}
								</FormGroup>
								<div className="text-left ms-4">
									<Button type="submit" color="warning" disabled={isSubmitting}>
										Enviar
									</Button>
								</div>
							</Form>
						);
					}}
				</Formik>
			) : (
				<div className="thx">
					Gracias por tu mensaje. Nos pondremos en contacto contigo
					próximamente.
				</div>
			)}
		</div>
	);
};

export default ContactForm;
