import { callApiPost } from './apiUtils';
import { MAIN_API_URL } from '../utils/helpers';

//--------------------

export const sendContact = async (userData) => {
	return await callApiPost(`${MAIN_API_URL}/sendContact`, userData);
};

export const sendNewsletter = async (userData) => {
	return await callApiPost(`${MAIN_API_URL}/sendNewsletter`, userData);
};
