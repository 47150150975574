import React from 'react';

//comps
import { Container, Col, Row, Button } from 'reactstrap';

import Newsletter from 'components/common/Newsletter';
import Reviews from 'components/common/Reviews';
import { NavLink, Link } from 'react-router-dom';

import { Slide } from 'react-awesome-reveal';

const HomePage = ({ appData }) => {
	return (
		<div className="home">
			<section className="hero">
				<Container>
					<Row className="align-items-center text-center">
						<Col md="12">
							<h1>TIME TO BE</h1>
							<h2>ORGANIZACIÓN Y PLANIFICACIÓN DE BODAS Y EVENTOS</h2>
							<Button
								className="btn btn-transparent"
								onClick={() =>
									document
										.getElementById('moments')
										.scrollIntoView({ behavior: 'smooth' })
								}
							>
								Descubre más
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="yy-5" id="moments">
				<Container>
					<Row>
						<Col md="7" className="mt-5 py-4 mx-auto">
							<div className="intro-section">
								<h1 className="tx-orange">El momento es ahora</h1>
								<p>{appData.home.momento}</p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5 bg-green doing">
				<Container>
					<Row>
						<Col md="8" className="mx-auto">
							<div className="intro-section">
								<h1>¿Qué hacemos?</h1>
							</div>
						</Col>
						<Col md="8" className="mx-auto">
							<Row>
								<Col md="6" className="p-4 d-flex align-items-stretch">
									<Slide direction="left" triggerOnce>
										<div className="home-box">
											<div className="photo">
												<img
													src="/images/home/modulo1.jpg"
													alt="El gran momento"
													className="img-fluid"
												/>
											</div>
											<div className="boton">
												<Link to="/servicios#el-gran-momento">
													<Button color="warning">Descubre más</Button>
												</Link>
											</div>
											<div className="text">El gran momento</div>
										</div>
									</Slide>
								</Col>
								<Col md="6" className="p-4 d-flex align-items-stretch">
									<Slide direction="right" triggerOnce>
										<div className="home-box">
											<div className="photo">
												<img
													src="/images/home/modulo2.jpg"
													alt="Cualquier momento es bueno para celebrar"
													className="img-fluid"
												/>
											</div>
											<div className="boton">
												<Link to="/servicios#cualquier-momento-es-bueno">
													<Button color="warning">Descubre más</Button>
												</Link>
											</div>
											<div className="text">
												Cualquier momento es bueno para celebrar
											</div>
										</div>
									</Slide>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="life pb-5">
				<Container>
					<Row>
						<Col md="8" className="mx-auto">
							<div className="intro-section">
								<h1>Te ayudamos a dar vida a ese momento</h1>
								<NavLink to="/contacto">
									<Button color="rosebig">Cuéntanoslo todo</Button>
								</NavLink>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="hello">
				<Container>
					<Row>
						<Col md="9" className="mt-5 mx-auto">
							<Row className="align-items-center">
								<Col md="6">
									<Slide direction="left" triggerOnce>
										<img
											src="/images/home/rocio.jpg"
											alt="Rocío González"
											className="img-fluid pe-5"
										/>
									</Slide>
								</Col>
								<Col md="6">
									<Slide direction="right" triggerOnce>
										<div className="soy-hello">
											<div className="signature">
												<img src="/images/home/r-sign.svg" alt="R Signature" />

												<div className="title">
													Soy
													<br />
													Rocío González
												</div>
											</div>

											<div className="text">{appData.home.soyroci}</div>
										</div>
									</Slide>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>

			<Container>
				<Row className="py-5">
					<Col lg="12">
						<h1 className="tx-green text-center">
							¡Momentos que hacen historia!
						</h1>
					</Col>
				</Row>
				<Reviews />
			</Container>
			<Newsletter />
		</div>
	);
};

export default HomePage;
