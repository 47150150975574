import React from 'react';

const LoadingLayer = ({ visible, hideAll = false }) => {
	return (
		visible && (
			<div className={`overlay ${hideAll ? 'full' : ''}`}>
				<div className="spinner"></div>
			</div>
		)
	);
};

export default LoadingLayer;
