import React from 'react';

import { Slide } from 'react-awesome-reveal';

//bs
import { Container, Row, Col } from 'reactstrap';

const MomentsPage = ({ appData }) => {
	return (
		<div className="momentos-page">
			<Container>
				<Row>
					<Col md="6" className="mx-auto">
						<div className="intro-section">
							<h1>MOMENTOS QUE SIEMPRE ESTARÁN EN NUESTRA RETINA</h1>
							<p>{appData.momentos.mainTx}</p>
						</div>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<div className="grid-momentos">
							{appData.momentos &&
								appData.momentos.gallery &&
								appData.momentos.gallery.map((item, index) => (
									<Slide key={index} direction="up" triggerOnce>
										<div key={index} className="momento">
											<img
												src={item.img}
												alt={item.title}
												className="img-fluid"
											/>
										</div>
									</Slide>
								))}
						</div>
					</Col>
				</Row>
			</Container>
			<div className="spacer-bottom"></div>
		</div>
	);
};

export default MomentsPage;
