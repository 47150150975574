// import { useEffect } from 'react';
// import { withRouter } from 'react-router-dom';

// const ScrollToTop = ({ children, location: { pathname } }) => {
//   useEffect(() => {
//     window.scrollTo({ top: 0, behavior: 'smooth' });
//   }, [pathname]);

//   return children;
// }

// export default withRouter(ScrollToTop);

import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = ({ children, location: { pathname, hash } }) => {
  useEffect(() => {
    const scrollToHashOrTop = () => {
      if (hash) {
        const targetElement = document.querySelector(hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    // Añadir un pequeño retraso antes de hacer scroll
    const scrollDelay = 100; // 100 milisegundos
    const timer = setTimeout(scrollToHashOrTop, scrollDelay);
    
    return () => clearTimeout(timer);
  }, [pathname, hash]);

  return children;
}

export default withRouter(ScrollToTop);