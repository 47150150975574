import React from 'react';

//bs
import { Col, Row } from 'reactstrap';

const Reviews = () => {
	return (
		<Row>
			<Col md="9" className="mt-5 mb-5 mx-auto">
				<div className="box-comments">
					<Row className='align-items-center'>
						<Col md="4">
							<div className="photo">
								<img
									src="/images/opiniones/pareja.png"
									alt="Rocío González Events"
									className="img-fluid"
								/>
							</div>
						</Col>
						<Col md="8">
							<div className="comment">
								<div className="pic">
									<img src="/images/opiniones/opinion1.png" alt="Opinion 1" />
								</div>
								<div className="text">
									<div className="name">MARYAM BAHÍA</div>
									<div className="starts">
										<img src="/images/opiniones/star-4.png" alt="4 estrellas" />
									</div>
									<div className="opi-tx">
										Si alguna vez imaginamos el día de nuestra boda, sin duda,
										nunca hubiera sido tan perfecto como lo que conseguiste tú y
										todo tu equipo, Rocio. Fue todo, no sólo la ayuda en el
										proceso, sino la tranquilidad y seguridad que nos
										transmitisteis en todo momento, implicándoos de manera
										incondicional en el proyecto. El día de la boda en ningún
										momento tuvimos nervios ni preocupación por nada. Estábamos
										seguros de que todo sería exactamente como fue, ¡PERFECTO!
										Muchos besos
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</Col>
		</Row>
	);
};

export default Reviews;
